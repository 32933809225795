<template>
    <div class="shop_main">
        <div class="shopBuyingDetail">
            <companySide :companyName="companyName" :companyId="companyId" @goSupplyBuyingDetail="goSupplyBuyingDetail" />

            <buyingInfo :id="id" :companyName="companyName" :companyId="companyId" type="1" />
        </div>
    </div>
</template>

<script>
  const companySide = () => import('../../../components/layout/shop/supplyBuying/companySide')
  const buyingInfo = () => import('../../../components/layout/shop/supplyBuying/buyingInfo')
  export default {
    name: "BuyingDetail",
    data () {
      return {
        companyName: this.$route.query.companyName,
        companyId: this.$route.query.companyId,
        id: this.$route.query.id,
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
    },
    methods: {
      goSupplyBuyingDetail(e) {
        if (e.type === 1) {
          this.id = e.id
        }
        if (e.type === 2) {
          this.$router.push({path: '/shop/supply/detail', query: {id: e.id, companyId: this.companyId, companyName: this.companyName}})
        }
      }
    },
    components: {
      companySide, buyingInfo
    }
  }
</script>
